import { dew as _INCRBYDew } from "./INCRBY";
import { dew as _INFODew } from "./INFO";
import { dew as _INITBYDIMDew } from "./INITBYDIM";
import { dew as _INITBYPROBDew } from "./INITBYPROB";
import { dew as _MERGEDew } from "./MERGE";
import { dew as _QUERYDew } from "./QUERY";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const INCRBY = _INCRBYDew();

  const INFO = _INFODew();

  const INITBYDIM = _INITBYDIMDew();

  const INITBYPROB = _INITBYPROBDew();

  const MERGE = _MERGEDew();

  const QUERY = _QUERYDew();

  exports.default = {
    INCRBY,
    incrBy: INCRBY,
    INFO,
    info: INFO,
    INITBYDIM,
    initByDim: INITBYDIM,
    INITBYPROB,
    initByProb: INITBYPROB,
    MERGE,
    merge: MERGE,
    QUERY,
    query: QUERY
  };
  return exports;
}