import { dew as _ADDDew } from "./ADD";
import { dew as _ADDNXDew } from "./ADDNX";
import { dew as _COUNTDew } from "./COUNT";
import { dew as _DELDew } from "./DEL";
import { dew as _EXISTSDew } from "./EXISTS";
import { dew as _INFODew } from "./INFO";
import { dew as _INSERTDew } from "./INSERT";
import { dew as _INSERTNXDew } from "./INSERTNX";
import { dew as _LOADCHUNKDew } from "./LOADCHUNK";
import { dew as _RESERVEDew } from "./RESERVE";
import { dew as _SCANDUMPDew } from "./SCANDUMP";
import * as _genericTransformers2 from "@redis/client/dist/lib/commands/generic-transformers";

var _genericTransformers = "default" in _genericTransformers2 ? _genericTransformers2.default : _genericTransformers2;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pushInsertOptions = void 0;

  const ADD = _ADDDew();

  const ADDNX = _ADDNXDew();

  const COUNT = _COUNTDew();

  const DEL = _DELDew();

  const EXISTS = _EXISTSDew();

  const INFO = _INFODew();

  const INSERT = _INSERTDew();

  const INSERTNX = _INSERTNXDew();

  const LOADCHUNK = _LOADCHUNKDew();

  const RESERVE = _RESERVEDew();

  const SCANDUMP = _SCANDUMPDew();

  const generic_transformers_1 = _genericTransformers;
  exports.default = {
    ADD,
    add: ADD,
    ADDNX,
    addNX: ADDNX,
    COUNT,
    count: COUNT,
    DEL,
    del: DEL,
    EXISTS,
    exists: EXISTS,
    INFO,
    info: INFO,
    INSERT,
    insert: INSERT,
    INSERTNX,
    insertNX: INSERTNX,
    LOADCHUNK,
    loadChunk: LOADCHUNK,
    RESERVE,
    reserve: RESERVE,
    SCANDUMP,
    scanDump: SCANDUMP
  };

  function pushInsertOptions(args, items, options) {
    if (options?.CAPACITY) {
      args.push("CAPACITY");
      args.push(options.CAPACITY.toString());
    }

    if (options?.NOCREATE) {
      args.push("NOCREATE");
    }

    args.push("ITEMS");
    return (0, generic_transformers_1.pushVerdictArguments)(args, items);
  }

  exports.pushInsertOptions = pushInsertOptions;
  return exports;
}