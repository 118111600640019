import { dew as _Dew } from ".";
import * as _genericTransformers2 from "@redis/client/dist/lib/commands/generic-transformers";

var _genericTransformers = "default" in _genericTransformers2 ? _genericTransformers2.default : _genericTransformers2;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.FIRST_KEY_INDEX = void 0;

  const _1 = _Dew();

  exports.FIRST_KEY_INDEX = 1;

  function transformArguments(key, items, options) {
    return (0, _1.pushInsertOptions)(["CF.INSERTNX", key], items, options);
  }

  exports.transformArguments = transformArguments;
  var generic_transformers_1 = _genericTransformers;
  Object.defineProperty(exports, "transformReply", {
    enumerable: true,
    get: function () {
      return generic_transformers_1.transformBooleanArrayReply;
    }
  });
  return exports;
}